<template>
<div class="productAndSolutionDetails">
  <CompanyProfileBanner :index="'2'" :imageUrl="imageUrl"></CompanyProfileBanner>
  <BreadcrumbAndSubtag
    :breadcrumbs="{
      path: parentParam.type === 'product' ? '/productSeries' : '/solution',
      name:  parentParam.type === 'product' ? '产品与技术' : '解决方案',
      childName: title === '' ? '' : title
    }"
  ></BreadcrumbAndSubtag>
  <div class="detailsContent">
    <div class="contents">
      <el-row v-show="parentParam.type === 'product'">
        <el-col :span="24" style="text-align: right">
          <el-button-group>
            <el-button v-show="tripleFoldUrl !==''" @click="handleClickDownLoadFun" type="primary">产品三折页<i class="el-icon-download el-icon--right"></i></el-button>
            <el-button @click="productNotesFun" type="primary">产品注意事项<i class="el-icon-share el-icon--right"></i></el-button>
            <el-button v-show="videosUrl.length === 1" type="primary" @click="previewVideoFun">产品使用视频<i class="el-icon-share el-icon--right"></i></el-button>
            <el-dropdown v-show="videosUrl.length > 1" type="primary" trigger="click" @command="handleCommandFun">
              <el-button type="primary">
                产品使用视频<i class="el-icon-share el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="el-icon-video-camera"
                  v-for="item in videosUrl"
                  :key="item"
                  :command="item"
                >{{ item.split('/')[item.split('/').length-1] }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button v-show="demoUrl !==''" @click="handleClickJumpFun" type="primary">展示demo<i class="el-icon-share el-icon--right"></i></el-button>
          </el-button-group>
        </el-col>
      </el-row>
      <div class="article">
        <h4>{{ title }}</h4>
        <div class="subtitle">
          <span>发布时间: {{ time }}</span>
          <span>浏览次数: {{ views }}</span>
        </div>
        <div class="articleContent" v-html="content"></div>
      </div>
      <p v-show="prevTitle.title">
        上一篇：<span class="switchArticles" @click="handleChangeArticlesFun(prevTitle.id)">{{ prevTitle.title }}</span>
      </p>
      <p v-show="nextTitle.title" style="margin-top: 4px">
        下一篇：<span class="switchArticles" @click="handleChangeArticlesFun(nextTitle.id)">{{ nextTitle.title }}</span>
      </p>
    </div>
  </div>
  <footerMain></footerMain>
  <el-dialog
    title="产品使用视频预览"
    :visible.sync="dialogPlay"
    width="50%"
    @close="closeDialog"
  >
    <video
      :src="videoUrl"
      controls
      autoplay
      class="video"
      ref="dialogVideo"
      width="100%"
    ></video>
  </el-dialog>
</div>
</template>

<script>
import CompanyProfileBanner from '@/components/companyProfileBanner'
import BreadcrumbAndSubtag from '@/components/breadcrumbAndSubtag'
import footerMain from '@/components/footerMain'
import Secret from "@/utils/secret";
import {mapGetters} from "vuex";

export default {
  name: "productAndSolutionDetails",
  components: {
    CompanyProfileBanner,
    BreadcrumbAndSubtag,
    footerMain
  },
  data() {
    return {
      id: null,
      imageUrl: '',
      demoUrl: '',
      tripleFoldUrl: '',
      videosUrl: [],
      dialogPlay: false,
      videoUrl: '',
      parentParam: JSON.parse(decodeURIComponent(atob(this.$route.query.data))),
      title: '',
      time: '',
      views: '',
      content: '',
      prevTitle: {},
      nextTitle: {},
    }
  },
  computed: {
    ...mapGetters(['articlesId', 'pageRefreshed'])
  },
  created() {
    this.loadResourceOfPageFun()
    this.getArticleContentFun()
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleUnload);
  },
  methods: {
    loadResourceOfPageFun() {
      if(this.parentParam.pageId) {
        const _param = {
          pageId: this.parentParam.type === 'product' ? 8 : 9,
          locationType: 1
        }
        api.getResourceOfPage(_param).then((res) => {
          if(res.code === 100) {
            this.imageUrl = resAddressPrefix + res.data[0].images[0].imageUrl
          }else {
            this.$message.error(res.msg);
          }
        }).catch((err) => {
          if(err.msg) {
            this.$message.error(err.msg);
          }
        });
      }
    },
    getArticleContentFun() {
      if(this.articlesId !== null && this.articlesId !== 'undefined') {
        const _param = {
          articleId: this.articlesId,
          pageId: this.parentParam.pageId
        }
        if(this.pageRefreshed !== 'true') {
          _param.addViewsFlag = 1
        }
        api.getArticleContent(_param).then((res) => {
          if(res.code === 100) {
            this.title = res.data.title
            this.time = Secret.ConvertToDateFun(res.data.updateTime, 'yyyy-MM-dd')
            this.views = res.data.views
            this.content = JSON.parse(res.data.content)
            this.demoUrl = res.data.demo ? res.data.demo : ''
            this.tripleFoldUrl = res.data.threeFold ? res.data.threeFold : ''
            this.videosUrl = res.data.usageVideo ? res.data.usageVideo.split(',') : []
            this.prevTitle = res.data.lastOne ? res.data.lastOne: {}
            this.nextTitle = res.data.nextOne ? res.data.nextOne : {}
          }else {
            this.$message.error(res.msg);
          }
        }).catch((err) => {
          if(err.msg) {
            this.$message.error(err.msg);
          }
        });
      } else {
        if(this.parentParam.type === 'solution') {
          this.$router.push({path: '/solution'})
        } else if(this.parentParam.type === 'product') {
          this.$router.push({path: '/productSeries'})
        }
      }
    },
    handleChangeArticlesFun(id) {
      this.$store.commit('SET_ARTICLES_ID', id)
      this.$store.commit('SET_PAGE_REFRESHED', 'false')
      this.getArticleContentFun()
    },
    handleUnload(event) {
      this.$store.commit('SET_PAGE_REFRESHED', 'true')
    },
    productNotesFun() {
      this.$store.commit('SET_SECONDARY_ROUTING_INDEX', "3-2")
      this.$router.push({ path: '/productSeries' })
    },
    handleClickDownLoadFun() {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.download = this.tripleFoldUrl.split('/')[this.tripleFoldUrl.split('/').length-1];
      a.href = resAddressPrefix + this.tripleFoldUrl;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    handleClickJumpFun() {
      window.open(this.demoUrl, '_blank');
      window.location.reload()
    },
    handleCommandFun(command) {
      this.dialogPlay = true;
      this.videoUrl = resAddressPrefix + command;
    },
    previewVideoFun() {
      this.dialogPlay = true;
      this.videoUrl = resAddressPrefix + this.videosUrl[0];
    },
    closeDialog() {
      this.videoUrl = ""; //清空数据 关闭视频播放
    },
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleUnload);
  },
}
</script>

<style scoped lang="scss">
.productAndSolutionDetails {
  .detailsContent {
    margin: auto;
    .contents {
      width: 1200px;
      padding: 2em 0;
      margin: auto;
      p {
        cursor: pointer;
        text-align: left;
        font-size: 14px;
        margin-bottom: 0px;
      }
      .article {
        padding: 10px 0 30px 0;
        .subtitle {
          font-size: 12px;
          color: #5a5a5a;
          padding-bottom: 20px;
          border-bottom: 1px solid #E5E5E5;
          span {
            margin-right: 20px;
          }
        }
        .articleContent {
          //padding-top: 20px;
          //p {
          //  text-indent: 2em;
          //}
          padding-top: 20px;
          text-align: left;
          //::v-deep span {
          //  text-indent: 2em;
          //  display: inline-block;
          //}
          ::v-deep p {
            text-indent: 2em;
          }
          ::v-deep figure {
            width: 100% !important;
            text-align: center;
          }
        }
      }
    }
    ::v-deep .el-dropdown {
      float: left;
      margin-right: -1px;
      button {
        border-radius: 0;
      }
    }
  }
}
</style>
